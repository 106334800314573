import Vue from 'vue'
import VueRouter from 'vue-router'
import { getCookie } from 'tiny-cookie';
import tenantRouters from './tenantRouters'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, resolve, reject) {
    if ( resolve || reject ) return originalPush.call(this, location, resolve, reject)
    return originalPush.call(this, location).catch((e)=> {return e})
}
Vue.use(VueRouter);

const tenant = getCookie("tenant");
const routerMap = new Map([
    ['default', tenantRouters],
])
const routeModule = routerMap.get(tenant) || routerMap.get("default");

const router = new VueRouter({
    mode: "history", 
    routes: routeModule
})

router.beforeEach( async (to,from,next) => {
    if (!to.query.rnd) {
        const toQuery = JSON.parse(JSON.stringify(to.query));
        toQuery.rnd = new Date().getTime()
        next({
            path: to.path,
            query: toQuery
        })
    } else {
        next()
    }
})

export default router
