import Vue from 'vue'
import App from './App.vue'
import store from './store';
import router from './router/index.js'
import "babel-polyfill"
import CacheData from "@/plugin/cache-data.js"
import AppInitialization from "@/plugin/app-initialization.js"
import getTenantIds from "@/plugin/getTenantIds.js"
//全局监听安卓返回
import '@/plugin/androidBackBridge';
// 引入rem布局
import { SmartRem } from 'smart-core-util'
SmartRem.install(document)
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);
import inputFilter from '@/common/inputFilter/inputFilter.js'
import {SmartStorage} from 'smart-core-util'
import iJsBridge from 'smart-javascript-bridge'
import '@/assets/css/public.scss'
// 引入cookie
import * as tinyCookie from 'tiny-cookie';
import fixAndroidInput from "@/plugin/fixAndroidInput";
Vue.use(fixAndroidInput);
// //全局引入组件
import SmartUI from 'smart-ui'
import 'smart-ui/lib/index.css'
import sm_header from "@/components/sm_header/sm_header";
Vue.component('sm_header', sm_header)
Vue.use(SmartUI);
import { Toast, Dialog } from 'vant';
import {httpService} from './service/httpService'
import publicFun from './service/publicFun'
import './common/filter/filter' // 全局filter
import {optionalChaining} from'@/common/optionalChaining'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper.scss'
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
// 创建全局bus监听对象
Vue.prototype.$eventHub = Vue.prototype.$eventHub || new Vue();
Vue.prototype.$service = httpService;
Vue.prototype.$publicFun = publicFun;
Vue.prototype.$SmartStorage = SmartStorage;
Vue.prototype.$cookie = tinyCookie;
Vue.prototype.$toast = Toast;
Vue.prototype.$dialog = Dialog;
Vue.prototype.$$ = optionalChaining;
Vue.prototype.inputFilter = inputFilter;
Vue.prototype.iJsBridge = new iJsBridge({ alert: Toast })

Promise.resolve().then(() => {
  return CacheData.install()
}).then(() => {
  return getTenantIds.install()
}).then(() => {
  return AppInitialization.install()
}).then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')
})
