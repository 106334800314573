import { SmartHttp } from 'smart-core-util'
const request = new SmartHttp({
    baseURL: process.env.VUE_APP_GATEWAY,
    // baseURL: "https://a1-gateway-test.roche.com.cn",
    // baseURL:"https://gateway.smartmice.cn",
    // baseURL: 'https://gateway-mice.smartmice.cn',
    disableLoadingAnimation: false,
    timeout: 20000
}).request;

const httpService = {
    //api前缀
    filterPath(path, noHead) {
        if (noHead) {
            return path;
        }
        return '' + path;
    },
    Get: (url, params) => {
        return request.get(url, params);
    },
    // 数据持久化-保存
    saveCacheData: (params) => {
        let data = {
            ...params,
            Strategy: "Session"
        }
        return request.post('/xchief/api/xcache/create', data);
    },
    // 数据持久化-获取
    queryCacheData: (params) => {
        return request.post('/api/statestore/redis/query', params);
    },
    // 获取 服务配置
    getsettings: (params) => {
        return request.post(`/api/tpm/cfg/getsettings`, params);
    },
    // 字典查询
    query: (params) => {
        return request.post('/foundation/dict/api/FnDictionary/Query', params);
    },
    // 获取酒店需求列表
    queryItems: (params) => {
        return request.get('/api/category/venue/item/query', {params: params});
    },
    // 删除酒店需求
    deleteItem: (params) => {
        return request.post('/api/category/venue/item/delete', params);
    },
    // 删除酒店需求
    deleteItemOld: (params) => {
        return request.post('/api/category/venue/ProposalXItem/DeleteItem', params);
    },
    // 提交报价 1，退回报价 2，退回结算3，退回损失 4
    createTask: (params) => {
        return request.post('api/category/venue/category/createtask',params);
    },
    pagingSupplier: (params) => {
        return request.post('api/category/venue/VenueSite/PagingSupplier',params);
    },
    pagingSupplier2: (params) => {
        return request.post('api/category/venue/VenueSite/pagingV2',params);
    },
    // 获取酒店详情
    getSupplier: (params) => {
        return request.get('/api/category/venue/VenueSite/GetSupplier', {params: params});
    },
    CheckMapSelection: (params) => {
        return request.post('api/category/venue/venuesite/CheckMapSelection',params);
    },
    // post 添加需求
    saveItem: (params) => {
        return request.post('api/category/venue/item/save',params);
    },
    // get 获取otherList
    GetConfirmTask: (params) => {
        return request.get('/api/category/venue/SupplierExpense/GetConfirmTask', {params:params});
    },
    QueryTaskActions: (params) => {
        return request.post(httpService.filterPath('api/foundation/workflow/FnFlow/QueryTaskActions', true), params);
    },
    //post 确认供应商报价
    supplierSelected: (params) => {
        return request.post(httpService.filterPath('api/category/venue/SupplierExpense/SupplierSelected', true), params);
    },
    //get 获取需求列表
    GetProposalItems: (params) => {
        return request.get(httpService.filterPath('/api/category/venue/SupplierExpense/GetProposalItems', true), {params:params});
    },
    // 获取需求列表
    ProcessTask: (params, headers = {}) => {
        return request.post('api/foundation/workflow/FnFlowTask/ProcessTask',params, headers);
    },
    // 获取需求列表
    updateLossStatus: (params) => {
        return request.post('api/category/venue/SupplierExpense/UpdateLossStatus',params);
    },
    //
    creatTask: (params) => {
        return request.post('api/category/venue/category/createtask',params);
    },
    //
    confirmSupplierSettle: (params, headers = {}) => {
        return request.post('api/category/venue/SupplierExpense/ConfirmSupplierSettle',params, headers);
    },
    //酒店
    QueryOrders: (params) => {
        return request.post('api/category/venue/Order/QueryOrders',params);
    },
    //酒店进程
    SupplierTaskLogs: (params) => {
        return request.post('api/foundation/workflow/FnFlow/SupplierTaskLogs',params);
    },
    //获取酒店订单列表
    PaingEOrder: (params) => {
        return request.post('api/eorder/order/Query', params);
    },
    //创建酒店订单
    CreateHotelEOrder: (params) => {
        return request.post('api/eorder/order/CreateHotelEOrder',params);
    },
    // 删除订单
    DeleteEOrders: (params) => {
        return request.post('/api/eorder/order/DeleteEOrders', params);
    },
    // 删除订单
    CancelEOrders: (params) => {
        return request.post('/api/eorder/order/CancelEOrders', params);
    },
    // 供应商下需求列表
    QueryOrderSupplierItemDetail: (params) => {
        return request.post('/api/category/venue/item/QueryOrderSupplierItemDetail', params);
    },
    // 订单下需求提交询价
    StartInquiry: (params) => {
        return request.post('/api/category/venue/item/StartInquiry', params);
    },
    // 订单下需求列表
    QueryOrderItemAndSupplierDetail: (params) => {
        return request.post('/api/category/venue/item/QueryOrderItemAndSupplierDetail', params);
    },
    // 订单下结算详情
    QueryOrderSupplierSettleExpense: (params) => {
        return request.post('/api/category/venue/item/QueryOrderSupplierSettleExpense', params);
    },
    // 订单下去支付获取数据
    QueryEOrderSupplierPayInfo: (params) => {
        return request.post('/api/eorder/order/QueryEOrderSupplierPayInfo', params);
    },
    // MOP获取报价数据
    QueryVenueSuppliers: (params) => {
        return request.post('/api/operation/operater/MiceData/QueryVenueSuppliers', params);
    },
    // MOP获取报价数据
    GetItemQuote: (params) => {
        return request.get('/api/category/venue/SupplierExpense/GetItemQuote', {params:params});
    },
    // MOP获取报价数据
    GetRequiredQuotes: (params) => {
        return request.post('/api/category/venue/Quote/GetRequiredQuotes', params);
    },
    // MOP提交报价数据
    SaveQuote: (params) => {
        return request.post('/api/category/venue/Quote/SaveQuotes', params);
    },
    // 查询文件
    queryFiles: (params) => {
        return request.post('/api/operation/operater/MiceData/QueryFiles', params);
    },
    // 查询预付款
    queryAdvances: (params) => {
        return request.post('/api/operation/operater/Payment/QueryAdvances', params);
    },
    // 获取上会人员
    getWorkers: (params) => {
        return request.post('/api/operation/operater/MiceData/GetWorkers', params);
    },
    // 查询条款
    queryClauses: (params) => {
        return request.post('/api/category/venue/Quote/QueryClauses', params);
    },
    // 保存条款
    SaveSupplierClause: (params) => {
        return request.post('/api/category/venue/SupplierExpense/SaveSupplierClause', params);
    },
    // 获取待办按钮配置
    MOPQueryTaskActions: (params) => {
        return request.post('/api/foundation/bpm/foundation/workflow/v2/Task/QueryTaskActions', params);
    },
    // 获取待办按钮提交接口
    MOPPerformTask: (params) => {
        return request.post('/api/foundation/bpm/foundation/workflow/v2/Task/PerformTask', params);
    },
    // 获取待办按钮提交接口
    GetF1QuotedPriceTaskList: (params) => {
        return request.post('/api/operation/operater/MiceData/GetF1QuotedPriceTaskList', params);
    },
    // 提交报价保存执行人信息
    SaveMiceAssistantInfo: (params) => {
        return request.post('/api/crossevent/EventOrder/SaveMiceAssistantInfo', params);
    },
    // 获取otherList--上会人员
    QueryGroup: (params) => {
        return request.post('/api/category/worker/Expense/QueryGroup', params);
    },
    // 获取会议酒店
    QueryVenueOrderSuppliers: (params) => {
        return request.post('/api/operation/operater/MiceData/QueryVenueOrderSuppliers', params);
    },
    // 获取会议酒店报价信息
    QueryVenueOrderSupplierItemExpenses: (params) => {
        return request.post('/api/operation/operater/MiceData/QueryVenueOrderSupplierItemExpenses', params);
    },
    //会议订单酒店会场房间
    GetItemVenueAndRooms: (params) => {
      return request.get("api/category/venue/category/GetItemVenueAndRooms", {params: params});
    },
    // MOP提交报价数据
    BatchSaveQuotes: (params) => {
        return request.post('/api/category/venue/Quote/BatchSaveQuotes', params);
    },
}
export { request, httpService }
