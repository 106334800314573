import { SmartStorage } from 'smart-core-util'
import { httpService } from '../service/httpService'
import { setCookie , getCookie, removeCookie} from 'tiny-cookie'

export default class CacheData {
    static install() {
        let url = window.location.search.substring(1);
        let urlParamsObj = {};
        if(url){
            let urlParams = url.split('&');
            urlParams.forEach(item => {
                let temp = item.split('=');
                urlParamsObj[temp[0]] = temp[1];
            })
        }
        if (urlParamsObj.sessionId) {
            setCookie("type", 'tenant');
        }
        let tenant_code = getCookie('tenant') || SmartStorage.get("tenant") || SmartStorage.get("tenant_code");
        if (['crossevent'].includes(tenant_code) && urlParamsObj && urlParamsObj.sessionId) {
            SmartStorage.remove('proposalId')
        }
        return new Promise(function (resolve) {
            if (urlParamsObj && urlParamsObj.sessionId) {
                    let params = {
                    "sessionId": urlParamsObj.sessionId || ""
                }

                httpService.queryCacheData(params).then(response => {
                    if(response.content) {
                        removeCookie('eventData')
                        removeCookie('eventData')
                        SmartStorage.remove('venueList')
                        SmartStorage.remove('eventData')
                        CacheData.setData(response.content)
                    }
                    resolve(response)
                }).catch(error => {
                    resolve(error)
                })
            }else {
                SmartStorage.set('eventData',JSON.parse(getCookie('eventData')))
                resolve()
            }
        })
    }

    // 设置缓存数据
    static setData(d) {
        let objKeyList = Object.keys(d);
        objKeyList.map(item => {
            SmartStorage.set(item, d[item]);
        });
        let expiresTimes = "31104000s";
        if (d.container == "h5") {
            expiresTimes = "Session";
        }
        setCookie('eventData', JSON.stringify(d.eventData), { expires: expiresTimes, domain: document.domain });
        !getCookie("token")&&CacheData.setCooikes(d)
        if(d.auth) {
            setCookie("token", d.auth.token || d.auth.access_token);
            setCookie("tenant", d.auth.tenantCode || d.auth.tenant_code);
            setCookie("tenant_id", d.auth.tenantId || d.auth.tenant_id);
            setCookie("container", d.auth.tenantId);
        }
        if(d.data) {
            let objKeyList = Object.keys(d.data);
            objKeyList.map(item => {
                SmartStorage.set(item, d.data[item]);
            });
            SmartStorage.set("proposalId", d.data.eventData.proposalId);
        }
    }
    static setCooikes(response) {
        let setting = {
            token: "token",
            userId: "userId",
            tenant_code: "tenant_code",
            tenant: "tenant_code",
            tenant_id: "tenant_id",
            role_codes: "role_codes",
            directory_id: "directory_id",
            supplierId: "supplierId",
            container:"container",
            platform:"platform",
        };
        // 获取过期时间
        let expiresTimes = "31104000s";
        if (response.container == "h5") {
            expiresTimes = "Session";
        }
        let config = { expires: expiresTimes, domain: document.domain };
        let config2 = { expires: expiresTimes, domain: window.idomain };
        CacheData.appendCookie(response, setting, config);
        CacheData.appendCookie(response, setting, config2);
    }
    // 添加到 cookie(注意：因为涉及多个站点共享cookie问题，所以需要设置主域名相同)
    static appendCookie(data, setting, config) {
        setCookie('token', data.access_token||data.token, config);
        setCookie('eventData', JSON.stringify(data.eventData), config);
        for (var i in setting) {
            i!='token'&&setCookie(i, data[setting[i]], config);
        }
    }
}
